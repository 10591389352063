import React from 'react'
import { NavLink } from 'react-router-dom'
import Highlights from '../Component/Highlights'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
const Header = () => {
    const handleCloseNavbar = () => {
        const navbarCollapse = document.getElementById('main_nav');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    };
    return (
        <>
            <div className="header">
                <div className="logo">
                    <LazyLoad><NavLink to="/"><img src="https://webapi.entab.info/api/image/MGMB/public/Images/logo.png" alt="MGMB" className="img-fluid" /></NavLink></LazyLoad>
                </div>
                <div className="header-nav">
                    <Highlights />
                    <nav className="navbar navbar-expand-lg">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item active" onClick={handleCloseNavbar}> <Link to="/"><i className="bi bi-house-door-fill"></i></Link></li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">School </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/OurSchool" onClick={handleCloseNavbar}>Our School</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurChurch" onClick={handleCloseNavbar}>Our Church</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurPatron" onClick={handleCloseNavbar}>Our Patron</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/MissionVision" onClick={handleCloseNavbar}>Mission &  Vision, Motto &  Aim </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/OurPrincipal" onClick={handleCloseNavbar}>Our Principal</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/hod" onClick={handleCloseNavbar}>Head of Departments </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/WorkingCommittee" onClick={handleCloseNavbar}>School Managing Committee</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Faculty" onClick={handleCloseNavbar}>Faculty</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SchoolTiming" onClick={handleCloseNavbar}>School Timing</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/PrayerAnthem" onClick={handleCloseNavbar}>Prayer &  Anthem</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/CareerGuidance" onClick={handleCloseNavbar}>Career Guidance &  Counselling</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="http://cbseacademic.nic.in/" target="_blank" onClick={handleCloseNavbar}>CBSE Website</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/MGMB/public/pdf/almanac.pdf" target="_blank" onClick={handleCloseNavbar}>Almanac</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/schoolCommunities" onClick={handleCloseNavbar}>School Communities</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">ACADEMICS </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/FeeDetails" onClick={handleCloseNavbar}>Fee Details</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/StudentCorner" onClick={handleCloseNavbar}>Student Corner</NavLink></li>
                                        <li><NavLink className="dropdown-item"  onClick={handleCloseNavbar} to="https://drive.google.com/file/d/1m2hXagFnN3K1dztu71A4SdDlNLXGpMzg/view?usp=sharingand" target="_blank">Mandatory Disclosure</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SubjectStudy" onClick={handleCloseNavbar}>Subjects of Study</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Tc" onClick={handleCloseNavbar}>TC</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ActivityCorner" onClick={handleCloseNavbar}>Educational Activities</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/SamplePaper" onClick={handleCloseNavbar}>Sample Paper </NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Syllabus" onClick={handleCloseNavbar}>Syllabus</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Booklist" onClick={handleCloseNavbar}>Book list</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/admission" onClick={handleCloseNavbar}>Admission</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/scholarship" onClick={handleCloseNavbar}>Scholarship</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/counselling" onClick={handleCloseNavbar}>Counsellor</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/achievement" onClick={handleCloseNavbar}>Achievement</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/notice" onClick={handleCloseNavbar}>Notice</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="https://webapi.entab.info/api/image/MGMB/public/pdf/prospectus.pdf" onClick={handleCloseNavbar}>Prospectus</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">INFRASTRUCTURE </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/Facilities" onClick={handleCloseNavbar}>Facilities</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/ClassRoom" onClick={handleCloseNavbar}>Class Room</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Laboratories" onClick={handleCloseNavbar}>Laboratories</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Library" onClick={handleCloseNavbar}>Library</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/PlayGround" onClick={handleCloseNavbar}>Play Ground</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/TinkeringLab" onClick={handleCloseNavbar}>Tinkering Lab</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item"><Link to="https://www.mgmbcampuscare.in/" target="_blank">ALUMNI</Link>  </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">MEDIA </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/BuildingProgram" onClick={handleCloseNavbar}>Capacity Building Program</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Activity" onClick={handleCloseNavbar}>Activity</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Gallery" onClick={handleCloseNavbar}>School Gallery</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/VideoGallery" onClick={handleCloseNavbar}>Video Gallery</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/EMagazine" onClick={handleCloseNavbar}>E - Magazine</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Certificates" onClick={handleCloseNavbar}>Certificates </NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link" data-bs-toggle="dropdown">HELP </div>
                                    <ul className="dropdown-menu fade-up">
                                        <li><NavLink className="dropdown-item" to="/ErpSoftware" onClick={handleCloseNavbar}>ERP Software</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/Pphelp" onClick={handleCloseNavbar}>Portal (Help?)</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="#" onClick={handleCloseNavbar}>Sitemap</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <div className="nav-link blinker"><NavLink to="https://www.mgmbcampuscare.in" target='_blank' onClick={handleCloseNavbar}>Admission </NavLink></div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Header
